<template>
  <el-dialog
    title="增值服务"
    :visible.sync="p_dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
    :before-close="handleClose"
    top="15vh"
  >
    <div class="container">
      <el-alert
        type="warning"
        title="1元 = 100点数，最低充值100元。续费年度版软件送 12000 点（非年度版每月送 600 点），升级到 plus 版本不限量。"
        :closable="false"
      ></el-alert>

      <div class="row-item" style="font-size: 1.5rem">
        充值人民币：
        <el-input-number
          v-model="price"
          style="margin-left: 1rem"
          :min="100"
          @change="priceChanged"
        ></el-input-number>
      </div>
      <div class="row-item" style="font-size: 1.5rem">
        可立即获得点数：{{ credit }}
      </div>
      <div class="row-item" style="width: 50%">
        <el-button style="width: 50%" type="primary" @click="handleBuyNow">
          立即购买
        </el-button>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button :size="size" @click="p_dialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" :size="size" @click="p_dialogVisible = false">
          确 定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { dialogMixin } from "@/common/js/mixin";
import notify from "@/common/js/notify";
import inviteUsers from "pages/tools/api/invite-users";
import { mapGetters } from "vuex";

export default {
  name: "ByAiPackage",
  // components: { MuTooltip },
  mixins: [dialogMixin],
  props: {},
  data() {
    return {
      size: "mini",
      price: 100,
      credit: 10000,
    };
  },
  computed: {
    ...mapGetters(["isPlusType", "isYearType"]),
  },
  // watch: {
  //   price(val) {
  //     this.credit = val * 100;
  //   },
  // },
  created() {},
  mounted() {},
  methods: {
    priceChanged(newPrice) {
      this.credit = newPrice * 100;
    },
    handleBuyNow() {
      const params = {
        type: "CREDIT",
        price: this.price,
      };
      inviteUsers.getBuyAiPackage(params).then((res) => {
        //支付宝支付
        if (res.status === 200) {
          // 添加之前先删除一下，如果单页面，页面不刷新，添加进去的内容会一直保留在页面中，二次调用form表单会出错
          let payFormDiv = document.getElementById("ali-pay-form-div");
          if (payFormDiv) {
            document.body.removeChild(payFormDiv);
          }
          const div = document.createElement("div");
          div.setAttribute("Id", "ali-pay-form-div");
          div.innerHTML = res.data; // data就是接口返回的form 表单字符串
          document.body.appendChild(div);
          // 修复购买包异常
          // console.log(document.forms);
          let aliPayForm = document.querySelector("form[name='punchout_form']");
          aliPayForm.setAttribute("target", "_blank");
          aliPayForm.submit();
        } else {
          notify.err("渲染异常,请稍后再试");
        }
      });
    },
  },
};
</script>

<style lang="stylus" scoped>

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-height: 70vh;
  overflow-y: auto;
  // margin-left: 0.5rem;

  .row-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2.5rem
  }
  .package {
    p {
      margin 1rem 0
    }

    .pay-group {
      display flex
      justify-content flex-start
      align-items center
      flex-wrap wrap

      .pay-item {
        display flex
        flex-direction column
        justify-content center
        width 15rem
        // margin -1px 0 0 -1px
        padding 20px 25px
        border 1px #e8eaec solid

        .discount-group {
          display flex
          justify-content right

          .discount {
            display flex
            justify-content center
            align-items center
            width 45px
            height 25px
            margin -20px -25px
            color #fff
            background-color #ff5722
            border-bottom-left-radius .5rem
          }
        }

        .pay-info {
          display flex
          flex-direction column
          justify-content center
          align-items center

          .price {
            font-size 2rem
            font-weight 700
            color #f60

            .source-price {
              margin-left .5rem
              font-size 1.375rem
              font-weight 400
              text-decoration line-through
              color #9da3ac
            }
          }
        }
      }
    }
  }
}
</style>
