<template>
  <operator-container>
    <template #content>
      <div class="container">
        <div class="">
          <el-row key="row-1" class="module-group-new" :gutter="10">
            <el-col :span="24">
              <div class="ge-dashboard__header dashboard-header-a5e9b">
                <div class="dashboard-header-greeting-93e60">
                  <!-- {{ timeOfDay }}好，{{
                    account.nick
                  }}！ -->

                  <!-- <div
                    style="
                      
                    "
                  > -->
                  <span>版本：{{ versionPlain }}</span>

                  <span>
                    服务到期时间：{{
                      account.erpEndDate || account.paymentEndDate
                    }}
                  </span>

                  <span>
                    发品数：{{ aiUseCount["releaseProductCount"] || "-" }}
                  </span>

                  <span>
                    改品数：{{ aiUseCount["updateProductCount"] || "-" }}
                  </span>

                  <span>
                    报告生成数：{{ aiUseCount["reportCount"] || "-" }}
                  </span>

                  <span>
                    剩余点数：{{ (isPlusType ? "不限" : remainCredit) || "-" }}
                  </span>
                  <span>
                    <el-link type="primary" underline @click="toCreditUsage">
                      明细记录
                    </el-link>
                  </span>
                  <span>
                    <!-- todo: 点击提示添加标签 -->
                    <div
                      v-if="
                        currentPluginVersion && currentPluginVersion >= '4.2.35'
                      "
                    >
                      <el-alert
                        v-if="!bookmarkAdded"
                        style="
                          padding: 0 !important;
                          background-color: transparent !important;
                        "
                        show-icon
                        :closable="false"
                        type="warning"
                        title="收藏本网址下次直接登录"
                      ></el-alert>
                      <el-alert
                        v-else
                        style="
                          padding: 0 !important;
                          background-color: transparent !important;
                        "
                        show-icon
                        :closable="false"
                        type="success"
                        title="已收藏本网址可直接登录"
                      ></el-alert>
                    </div>
                    <el-alert
                      v-else
                      style="
                        padding: 0 !important;
                        background-color: transparent !important;
                      "
                      show-icon
                      :closable="false"
                      type="warning"
                      title="收藏本网址下次直接登录"
                    ></el-alert>
                  </span>
                  <!-- </span> -->
                </div>
                <!-- <div
                  class="dashboard-header-greeting-93e60"
                  style="float: right"
                >
                  当前北京时间
                  {{ currentTime }}
                </div> -->
              </div>

              <!-- <el-alert :closable="false" type="warning">
                <div class="header-tooltip">
                  首次使用必看
                  <el-link
                    :underline="false"
                    type="primary"
                    @click="clickShowFirstUsage"
                  >
                    AI 操盘手主要功能视频演示
                    <i class="el-icon-video-camera"></i>
                  </el-link>
                </div>
              </el-alert> -->
              <!-- <el-alert :closable="false" type="warning">
                <div class="header-tooltip">
                  使用本软件系统需要安装插件，
                  <el-link
                    href="https://www.yuque.com/yogurt/kb/zx2usuzlwmmdm61o"
                    target="__blank"
                    >插件安装教程 </el-link
                  >。
                </div>
              </el-alert> -->
              <!-- <el-alert :closable="false" type="warning">
                <div class="header-tooltip">
                  如使用过程遇到问题，可咨询右上角客服，或自行查看
                  <el-link
                    type="primary"
                    target="__blank"
                    href="https://www.yuque.com/yogurt/kb/cnskku"
                  >
                    软件使用完整教程
                  </el-link>
                </div>
              </el-alert> -->
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <div class="header-banner">
                <el-carousel interval="5000">
                  <el-carousel-item
                    v-for="item in bannerImgs"
                    :key="item.url"
                    style="display: flex; align-items: center"
                  >
                    <el-image
                      style="height: 100%"
                      fit="cover"
                      :src="item.url"
                      @click="clickBannerImageToTry(item)"
                    ></el-image>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </el-col>
          </el-row>
          <el-row
            v-for="(item, index) in options.functionModuleListNew"
            :key="index"
            class="module-group-new"
            :gutter="10"
          >
            <el-col
              v-for="(subItem, subIndex) in item"
              :key="subIndex"
              :span="subItem.span || 24 / item.length"
            >
              <!-- <el-collapse v-model="collapseName" @change="collapseChanged">
                <el-collapse-item :name="index">
                  <template #title> -->
              <!-- <div
                v-if="subItem.moduleName || subItem.description"
                :title="subItem.description"
                style="
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                "
                class="module-title"
              >
                <span
                  v-if="subItem.moduleName"
                  class="subject"
                  :style="{ 'font-size': moduleTitleFontSize }"
                >
                  {{ subItem.moduleName }}
                </span>
                <span
                  v-if="subItem.description"
                  :title="subItem.description"
                  class="description"
                  :style="{
                    'font-size': descriptionFontSize,
                  }"
                  v-html="`${subItem.description}`"
                >
                </span>
              </div> -->
              <!-- </template> -->
              <el-row class="module-content" flex justify="space-between">
                <el-col
                  v-for="(child, index2) in subItem.children"
                  :key="index2"
                  class="module-item"
                  :span="child.span || 24 / subItem.children.length"
                >
                  <!-- <div v-if="child.videoSrc" class="video-item">
                    <div class="video-item-content">
                      <div class="list-item-image" @click="clickImage(child)">
                        <img
                          :src="child.imageUrl"
                          class="image"
                          style="margin-top: 0px"
                        />
                        <span class="length">{{ child.videoLength }}</span>
                        <div class="mask list-item-play-mask">
                          <i class="el-icon-video-play play-icon"></i>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <img
                    v-if="child.imageUrl"
                    :src="child.imageUrl"
                    alt="暂无图片"
                    :style="entryImageStyle"
                    @click="handleModule(child)"
                  />
                  <span class="title">
                    <span v-html="child.title"></span>
                    <el-link
                      v-if="child.videoSrc"
                      style="margin-left: 0.25rem; font-size: 12px"
                      type="primary"
                      :underline="false"
                      @click="
                        clickImage({
                          videoSrc: child.videoSrc,
                          title: child.title,
                        })
                      "
                    >
                      视频教程
                      <i class="el-icon-video-camera"></i>
                    </el-link>
                  </span>
                </el-col>
                <!-- <el-col :span="12" v-if="index === 0">
                  <el-card style="border-radius: 0.75rem">
                    Fuqing Shengde Plastic & Rubber Products Co., Ltd.
                  </el-card>
                </el-col> -->
              </el-row>
              <!-- </el-collapse-item>
              </el-collapse> -->
            </el-col>
          </el-row>
        </div>
        <!-- <el-divider></el-divider> -->
        <!-- <div>
          <ai-landing-page></ai-landing-page>
        </div> -->
      </div>
      <!-- <div
        v-if="!isYearType"
        class="fix-footer-promotion"
        style="display: flex; align-items: center; justify-content: center"
      >
        <div
          style="
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
          "
        >
          <el-image
            style="max-width: 3.5rem"
            src="https://assets.maoniux.com/images/maoxiaoniu.png"
          ></el-image>
          <span style="font-size: 2.75rem; color: white; font-weight: 300">
            限时特惠，买一年送一年（限服务期内联系客服申请）
          </span>
        </div>
      </div> -->
    </template>
    <template #dialog>
      <video-player-dialog
        :video-name="videoName"
        :dialog-visible.sync="videoPlayerDialogVisible"
        :video-path="videoPath"
      >
      </video-player-dialog>

      <product-quotation-image
        :dialog-visible.sync="status.showProductQuotationImageDialog"
      >
      </product-quotation-image>
      <buy-ai-package :dialog-visible.sync="status.showBuyAiPackageDialog">
      </buy-ai-package>
    </template>
  </operator-container>
</template>

<script>
import Api from "@/api";
import axios from "@/common/js/ajax";
import { AI_SPIRIT_URL } from "@/common/js/config";
import notify from "@/common/js/notify";
import OperatorContainer from "@/components/container/operator-container.vue";
// import MuQuestion from "@/components/mu-question/mu-question.vue";
import BuyAiPackage from "@/pages/tools/views/dialog/buy-ai-package.vue";
import ProductQuotationImage from "@/pages/tools/views/dialog/product-quotation-image.vue";
import { mapGetters } from "vuex";
// import ProcessingRecords from "../intelligent-diagnosis/processingRecords.vue";
// import aiLandingPage from "../ai-landing-page/ai-landing-page.vue";
import {
  AI_SUPERMAN_VIDEO_ARTIFACT_VERSION,
  PRODUCT_RELEASE_AI_HELPER_VERSION,
} from "@/common/const/video-course";
import { getAiUseCountInfo } from "@/common/utils/aiUseLimitUtils";
import videoPlayerDialog from "@/pages/product/views/video-bank/dialog/videoPlayerDialog.vue";
import moment from "moment";
import { getCreditUsage } from "../../api/credit-usage";
import { ROUTER_TYPES } from "../../router/router-types";

export default {
  name: "AiHomePage",
  components: {
    // MuTooltip,
    OperatorContainer,
    // ProcessingRecords,
    // MuQuestion,
    ProductQuotationImage,
    BuyAiPackage,
    // aiLandingPage,
    videoPlayerDialog,
  },
  mixins: [],
  props: {},
  data() {
    return {
      currentTime: moment().format("YYYY-MM-DD HH:mm:ss"),
      videoName: "",
      collapseName: [0, 1, 2, 3],
      size: "mini",
      videoPlayerDialogVisible: false,
      videoPath: "",
      options: {
        functionModuleListNew: [
          [
            {
              moduleName: "产品板块",
              description:
                "一键采集产品，自动填写参数，智能检测产品质量分，零效果秒变新发品；AI智能抠图，图片翻译，处理疑似盗图，在线PS。",
              children: [
                {
                  imageUrl: require("./images/collectProduct.png"),
                  title: "采集发品",
                  description: "一键采集1688产品和阿里巴巴产品",
                  path: `/product.html#/new-product?name=showProductCollectDialog`,
                  videoSrc: PRODUCT_RELEASE_AI_HELPER_VERSION,
                },
                // {
                //   imageUrl: require("./images/batchReleaseProduct.png"),
                //   title: "批量发新品",
                //   description: "批量发布新产品",
                //   path: `/product.html#/new-product?name=categorySelect`,
                //   videoSrc: PRODUCT_RELEASE_DETAIL_VIDEO_FAST_VERSION,
                // },
                {
                  imageUrl: require("./images/batchEditOldProduct.png"),
                  title: "批量改老品",
                  description: "批量修改老产品",
                  path: `/product.html#/published-product`,
                },

                {
                  imageUrl: require("./images/zeroEffectProduct.png"),
                  title: "零效果转新发",
                  description: "批量发布零效果产品",
                  path: `/product.html#/new-product?name=showNoEffectProduct`,
                },
                {
                  imageUrl: require("./images/banner-manage.png"),
                  title: "爆款关联",
                  description: "图片银行",
                  path: `/product.html#/banner-manage`,
                },
              ],
            },
          ],
          [
            {
              moduleName: "数据板块",
              description:
                "高阶运营的数据分析体系，一键生成店铺自身，标杆同行，优秀同行，行业热品报告",
              children: [
                {
                  imageUrl: require("./images/360DiagnosisReport.png"),
                  title: "我的店铺360复盘",
                  description: "一键生成店铺诊断报告",
                  path: `/data-report.html#/report`,
                },

                {
                  imageUrl: require("./images/competitors-analyse.png"),
                  title: "指定同行询盘数查询",
                  description: "分析同行店铺数据",
                  path: `/data-report.html#/industry-hot-inquiries`,
                },
                {
                  imageUrl: require("./images/industryReport.png"),
                  title: "询盘top20店铺明细表",
                  description: "一键生成标杆同行",
                  path: `/data-report.html#/industry-hot-products`,
                },
                {
                  imageUrl: require("./images/hotProductReport.png"),
                  title: "询盘top20产品明细表",
                  description: "一键生成热品报告",
                  path: `/data-report.html#/hot-products-report`,
                },
              ],
            },
          ],
          [
            // {
            //   span: 8,
            //   moduleName: "关键词",
            //   description:
            //     "一键收集关键词，关键词AI翻译，批量查排名，查使用次数",
            //   children: [],
            // },
            // {
            //   span: 4,
            //   moduleName: "P4P",
            //   description:
            //     "支持分时推广，智能调价，针对高流量关键词和产品进行数据监控",
            //   children: [],
            // },

            {
              // span: 12,
              moduleName: "更多功能",
              description:
                "关键词收集，P4P推广计划，选中1688产品自动生成报价单，导出阿里历史所有客户邮箱进行邮件营销等",
              children: [
                {
                  imageUrl: require("./images/keywordCollect.png"),
                  title: "词收集",
                  description: "一键采集阿里热搜词",
                  path: `/keyword.html#/word-collection`,
                },
                // {
                //   imageUrl: require("./images/shieldKeyword.png"),
                //   title: "屏蔽词",
                //   description: "屏蔽词管理",
                //   path: `/keyword.html#/unavailable-word`,
                // },
                {
                  imageUrl: require("./images/keywordsLibrary.png"),
                  title: "关键词库",
                  description: "关键词管理",
                  path: `/keyword.html#/keyword-library`,
                },
                {
                  imageUrl: require("./images/campaign.png"),
                  title: "分时推广和自动竞价",
                  description: "阿里营销推广方案管理",
                  path: `/p4p-new.html#/promotion-plan-overview`,
                },
                // {
                //   imageUrl: require("./images/hightFlowKeyword.png"),
                //   title: "高流量词",
                //   description: "查看各推广计划下高流量词",
                //   path: `/p4p-new.html#/high-traffic-keywords`,
                // },
                // {
                //   imageUrl: require("./images/hightFlowProduct.png"),
                //   title: "高流量产品",
                //   description: "查看各推广计划下高流量产品",
                //   path: `/p4p-new.html#/high-traffic-products`,
                // },
                {
                  imageUrl: require("./images/productQuotation.png"),
                  title: "生成报价单",
                  description: "根据1688图片搜索导出产品报价单",
                  dialogName: "showProductQuotationImageDialog",
                },
                // {
                //   imageUrl: require("./images/customerEmail.png"),
                //   title: "客户邮箱导出",
                //   description: "导出阿里客户名片",
                //   path: AI_SPIRIT_URL,
                // },
                // {
                //   imageUrl: require("./images/resourcePackage.png"),
                //   title: "增购资源包",
                //   description: "购买软件模块资源包",
                //   dialogName: "showBuyAiPackageDialog",
                // },
              ],
            },
          ],
        ],
      },
      handleStatus: false,
      expertCoordinate: false,

      moduleTitleFontSize: "1.5rem",
      descriptionFontSize: "1rem",
      imageSize: "4.25rem",
      imageMargin: "1.25rem 0",
      status: {
        showProductQuotationImageDialog: false,
        showBuyAiPackageDialog: false,
        recordDialogVisible: false,
      },
      aiUseCount: {},

      bannerImgs: [
        //   {
        //     url: "https://assets.maoniux.com/images/superman-banner-1.jpg",
        //     redirect: "https://saas.maoniunet.com/try",
        //   },
        //   {
        //     url: "https://assets.maoniux.com/images/superman-banner-2.jpg",
        //     redirect: "https://saas.maoniunet.com/try",
        //   },
        {
          url: "https://assets.maoniux.com/images/superman-banner-3.jpg",
          redirect: "https://saas.maoniunet.com/try",
        },
      ],
      remainCredit: 0,
    };
  },
  computed: {
    ...mapGetters(["bookmarkAdded", "currentPluginVersion"]),
    entryImageStyle() {
      return {
        width: this.imageSize,
        height: this.imageSize,
        margin: this.imageMargin,
      };
    },
    timeOfDay() {
      const currentHour = new Date().getHours();

      if (currentHour >= 0 && currentHour < 6) {
        return "午夜";
      } else if (currentHour >= 6 && currentHour < 12) {
        return "上午";
      } else if (currentHour >= 12 && currentHour < 14) {
        return "中午";
      } else if (currentHour >= 14 && currentHour < 18) {
        return "下午";
      } else {
        return "晚上";
      }
    },
    currentChildHandle() {
      let options = {};
      this.childHandle
        .filter((ch) => ch.seen)
        .forEach((ch) => {
          options[ch.name] = ch.status;
        });
      return options;
    },
    getAiOptimizeContent() {
      if (this.isBasicType) {
        return "当前版本为基础版，不支持该功能，请联系客服升级为 Plus 版本";
      }
      return "开启后即代表你已授权AI操盘手智能对关键词，产品，橱窗，直通车等，根据算法进行优化处理";
    },
    versionPlain() {
      const suffix = this.isPlusType ? " Plus 版本" : "标准版";
      if (this.isYearType) {
        return "年度" + suffix;
      } else if (this.isSeasonType) {
        return "季度" + suffix;
      } else if (this.isMonthType) {
        return "月度" + suffix;
      } else {
        return "7天试用版";
      }
    },
    ...mapGetters([
      "account",
      "isBasicType",
      "isPlusType",
      "isYearType",
      "isSeasonType",
      "isMonthType",
      "isWeekType",
    ]),
  },
  watch: {},
  created() {
    // setInterval(() => {
    //   this.currentTime = moment().format("YYYY-MM-DD HH:mm:ss");
    // }, 1000);
    this.getHandleStatus();
    this.setPageItemSize();

    getAiUseCountInfo().then((res) => {
      this.aiUseCount = res;
    });
    getCreditUsage().then((res) => {
      this.remainCredit = (
        res?.data?.creditLimit - res?.data?.creditUsage
      ).toFixed(2);
    });
  },
  mounted() {},
  methods: {
    toCreditUsage() {
      this.$router.push({
        path: ROUTER_TYPES.CREDIT_USAGE,
      });
    },
    clickBannerImageToTry(item) {
      window.open(item.redirect, "__blank");
    },
    clickShowFirstUsage() {
      this.clickImage({
        videoSrc: AI_SUPERMAN_VIDEO_ARTIFACT_VERSION,
        title: `软件主要功能评测及演示`,
      });
    },
    collapseChanged(val) {
      console.log(val);
      if (val?.length > 2) {
        this.collapseName = [...val.slice(1)];
      }
    },

    handleModule(child) {
      const { path, dialogName } = child;
      if (dialogName) {
        return (this.status[dialogName] = true);
      }
      if (path === AI_SPIRIT_URL) {
        return window.open(path, "_blank");
      }
      return window.open(location.origin + path, "_blank");
    },
    modifyStatus(type) {
      let url = Api.putDiagnosisExpertStatus.replace(
        "{status}",
        this.expertCoordinate
      );
      if (type === "handleStatus") {
        url = Api.putDiagnosisHandleStatus.replace(
          "{status}",
          this.handleStatus
        );
        if (!this.handleStatus) {
          this.childHandle.forEach((ch) => {
            ch.status = false;
          });
        }
      }
      this.modifyChildHandle(url);
      this.$refs.popperRef.setExpectedState(true);
    },
    handleChildChange() {
      const closeAll = this.childHandle.some((op) => op.status);
      if (!closeAll) {
        this.handleStatus = false;
      } else if (!this.handleStatus) {
        this.handleStatus = true;
      }
      this.modifyChildHandle(
        Api.getDiagnosisHandleOptions,
        this.currentChildHandle
      );
    },
    clickImage() {
      window.open("//www.maoniunet.com/help", "_blank");
    },
    modifyChildHandle(url, data) {
      axios({
        method: "put",
        url: url,
        data: data,
      })
        .then(() => {
          this.getHandleStatus();
        })
        .catch(() => {
          notify.err({ message: "修改失败" });
          this.getHandleStatus();
        });
    },
    setPageItemSize() {
      if (window.innerWidth < 1920) {
        // this.descriptionFontSize = "14px";
        // this.moduleTitleFontSize = "1.3rem";
        // this.imageSize = "4.8rem";
        // this.imageMargin = "1rem 0";
      }
    },
    getHandleStatus() {
      const url = Api.getDiagnosisHandle;
      axios({
        method: "get",
        url: url,
      })
        .then((res) => {
          this.handleStatus = res.status;
          this.expertCoordinate = res.expertCoordinate;
          const options = res.options;
          this.childHandle.forEach((ch) => {
            const option = options[ch.name];
            if (option != null) {
              ch.seen = true;
            }
          });
          if (this.handleStatus) {
            this.childHandle.forEach((ch) => {
              ch.status = options[ch.name];
              ch.disabled = false;
            });
          } else {
            this.childHandle.forEach((ch) => {
              ch.status = false;
              ch.disabled = true;
            });
          }
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="stylus">
.header-banner .el-carousel__container {
  max-height: 170px !important;
}
</style>
<style lang="stylus" scoped>

.header-banner {
  // height: calc(100vh - 800px);
}

.fix-footer-promotion {
  position: fixed;
  height: 80px;
  background-color: rgba(220, 38, 38, 0.5);
  width: 100vw;
  left: 0;
  bottom: 0;
}

.dashboard-header-a5e9b {
  align-items: center;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  // height: 60px;
  justify-content: space-between;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  transition: background-color .2s ease;

  margin-bottom: 0.5rem;
  .dashboard-header-greeting-93e60 {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px
    color: #2e405e;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}


.el-alert + .el-alert {
  margin-top: 0.25rem;
}
.module-group-new {
  margin: 0.5vh 0
  .el-collapse {
    border-top: 0 !important;
    border-bottom: 1px solid #EBEEF5;
  }
}
.container {
  margin: auto;
  padding 0 0.5vw;
  .container-nav {
    display flex
    justify-content right
    align-items center
  }
}

.module-group-new + .module-group-new {
  margin-bottom: 0.5rem;
}

.module-group-new {
  .header-tooltip {
    .el-link {
      // font-size: 1.25rem
      // color: coral;
    }
    font-size: 14px;
    font-weight 400
    vertical-align middle
    // color #17233d
  }
  .module-title {
    margin: 0.5rem 0;
    // height 3rem
    // line-height 1.5rem
    // margin-top 0.8rem


    .subject {
      font-size 1.5rem
      font-weight 500
      vertical-align middle
      color #17233d
    }
    .description {
      vertical-align middle
      color #888
    }
  }
  .module-content:not(:has(.video-item)) {
    align-items center
    // margin-right 1rem
    border-radius 0.5rem
    background-color #f3f3f3
  }

  .module-item{
    display flex
    flex-direction column
    justify-content center
    align-items center
    flex-wrap nowrap
    line-height 2rem

    img {
      margin: 1.25rem 0;
      width 6.25rem
      height 6.25rem
      object-fit contain
      transition: all .5s;
    }

    img:hover {
      transform scale(1.2)
      transition all .2
    }

    .title {
      margin 0.35rem 0
      font-size 1rem
      color #17233d
    }

    .sub-title {
      color #515a6e
    }
  }
}

.video-item {
  display flex
  flex-direction column
  justify-content flex-start
  align-items center
  width 145px
  height auto
  margin-right 20px
  padding-top 10px
  .video-item-content {
    position relative
    width 100%
    height 100%
    padding 0
    margin 0
    .list-item-image{
      display flex;
      align-items  center;
      position relative;
      // background-color #ffffff
      // height 145px
      .image{
        width 100%
        height 100%
        display block;
        vertical-align middle;
        border-radius 6px
      }
      .length{
        background: #111;
        background: rgba(0,0,0,.5);
        border-radius: 5px 0 0 0;
        color: #fff;
        line-height: 20px;
        transition: top .2s ease;
        padding: 0 6px;
        position: absolute;
        right: 0;
        bottom: 0;
      }
      .list-item-play-mask{
        position absolute;
        top: 0;
        background-color: rgba(0,0,0,.2);
        color: #fff;
        height: 100%;
        width: 100%;
        display none;
        font-size 30px;
        .play-icon{
        }
      }
    }
    .list-item-image:hover .list-item-play-mask{
      display flex;
      align-items center;
      justify-content center;
    }

    .video-title{
      margin-top 6px
    }
  }
}
.handle-slot {
  width: 400px
  background-color: #f1f1f163

  .top-handle {
    text-align: center
    margin: 0.5rem auto
  }

  .second-handle {
    margin: 0.5rem 2rem
  }

}
</style>
<!-- <style>
.sc-launcher {
  bottom: 100px !important;
  right: 30px !important;
  position: fixed;
}
.sc-open-icon,
.sc-closed-icon {
  bottom: 114px !important;
  right: 43px !important;
  height: auto !important;
  width: auto !important;
  padding: 0px !important;
  position: fixed;
}
.sc-chat-window {
  bottom: 170px !important;
  right: 65px !important;
  position: fixed;
}
</style> -->
